<template>
        <main-template @slotLoaded="loadUsers">
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>HQ</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <HqNavigation :links="slotProps.links"/>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (userDetails || loadingDetails) && !hideTable}">
                                <loading v-if="!response || !users"></loading>
                                <template v-else>
                                    <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                        <HrPeoplePagination ref="peopleTable" :users="users" @setUserDetails="showUserDetails" @setLoading="setLoading" :response="userResponse" @altViewClicked="altViewClicked" page="hq-people" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20">
                                            <template v-slot:additional-buttons>
                                                <form-action
                                                    v-if="userResponse && userResponse.body.actions.filter(action => action.name === 'add-new-user').first()"
                                                    :action="userResponse.body.actions.filter(action => action.name === 'add-new-user').first()"
                                                    :redirect-on-success="false"
                                                    @success="(res)=>{ loadUsers(slotProps); userCreated(res)}"
                                                ></form-action>
                                            </template>
                                        </HrPeoplePagination>
                                    </div>
                                    <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden" v-if="userDetails || loadingDetails">
                                        <hr-person-single ref="singleDetails" v-if="userDetails" :userDetails="userDetails" :editing="editing" :update-action="updateAction" @saveClicked="saveClicked(slotProps)" :hideTable="hideTable" @hideTable="toggleHideTable" page="hq-people" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20"></hr-person-single>
                                        <loading v-else class="sticky top-0"></loading>
                                    </div>

                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import FetchSirenRoot from '@/components/FetchSirenRoot';
import FormAction from "@/v3/components/forms/FormAction.vue";
import fileDownload from "js-file-download";
import HeaderLink from "@/v3/components/headers/HeaderLink.vue";
import HqNavigation from "@/v3/components/navigation/HqNavigation.vue";
import HrAccountsPagination from "@/v3/components/pagination/HrAccountsPagination.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrPersonEmploymentPagination from "@/v3/components/pagination/HrPersonEmploymentPagination.vue";
import HrPersonSingle from "@/v3/components/HrPersonSingle.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";

export default {
    components: {
        FormAction,
        HqNavigation,
        HrPersonSingle,
        HeaderLink,
        BlankHeader,
        HrPersonEmploymentPagination,
        HrAccountsPagination,
        HrSinglePersonPagination,
        HrPeoplePagination,
        FetchSirenRoot,
        Loading,
        MainTemplate,
    },
    data() {
        return {
            slotProps: null,
            users: null,
            userDetails: null,
            singleUserDetailsOpen: false,
            loadingDetails: false,
            userResponse: null,
            accountsDetailsOpen: false,
            employmentDetailsOpen: false,
            editing: false,
            submitting: false,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            return this.$route.fullPath.replace('/v3','')
        },
        initials() {
            if (this.userDetails && this.userDetails.properties.get('name')) {
                return this.userDetails.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        updateAction() {
            if (!this.userDetails) {
                return null;
            }
            return this.userDetails.actions.filter(action => action.name === 'update-user').first()
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        loadUsers(slotProps){
            this.singleUserDetailsOpen = false;
            this.accountsDetailsOpen = false;
            this.employmentDetailsOpen = false;
            this.usersUrl = slotProps.links.filter(link => link.rels.includes('admin.client.users')).first().href;
            let params = this.$router.currentRoute.fullPath.split('?')[1]? this.$router.currentRoute.fullPath.split('?')[1] : null
            let url = this.usersUrl;
            if(params){
                url = url + '?' + params + '&page-name=hq-people';
            }else{
                url = url + '?page-name=hq-people';
            }
            Siren.get(url).then(res => {
                this.users = res.body.entities;
                this.userResponse = res;
            }, this)
            return this.users;
        },
        showUserDetails(userDetails){
            this.tabOpen= 'basics';
            this.editing = false;
            this.userDetails = userDetails;
            this.loadingDetails = false;
        },
        setLoading(){
            this.userDetails = null;
            this.loadingDetails = true;
        },
        saveClicked(slotProps) {
            if(!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.updateAction.perform(this.$refs.singleDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.singleDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.loadUsers(slotProps);
                    this.userDetails = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        altViewClicked(){
            this.userDetails = null;
            this.$refs.peopleTable.closeDetails();
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_light"
        },
        userCreated(res){
            this.showUserDetails(res.body);
            this.$refs.peopleTable.viewUser = true;
        },
    }
}
</script>

